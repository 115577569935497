<template>
  <div class="q-token" :class="`q-token--${color}`">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'QToken',
  props: {
    color: {
      type: String,
      default () {
        return 'default'
      }
    }
  }
}
</script>

<style>
.q-token {
  background-color: #fff;
  border: 1px dotted #00b4ff;
  border-radius: 5px;
  color: #00b4ff;
  display: inline-block;
  font-family: inherit;
  line-height: 1.2;
  padding: 2px 4px;
}

.q-token.q-token--default {
  border-color: #00b4ff;
  color: #00b4ff;
}

.q-token.q-token--teal {
  border-color: #009688;
  color: #009688;
}

.q-token.q-token--warning {
  border-color: #F2C037;
  color: #F2C037;
}

.q-token.q-token--positive {
  border-color: #21BA45;
  color: #21BA45;
}

.q-token.q-token--negative {
  border-color: #C10015;
  color: #C10015;
}
</style>